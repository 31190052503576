var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isValid
        ? _c("form-summary", {
            staticClass: "form-errors alert alert-danger",
            attrs: { validator: _vm.$v.form, attributes: _vm.attributes },
          })
        : _vm._e(),
      _c("LinkedTypes", {
        attrs: {
          restaurantId: _vm.itemId,
          linkedTypes: _vm.form.restaurant_types,
        },
        on: {
          "update:linkedTypes": function ($event) {
            return _vm.$set(_vm.form, "restaurant_types", $event)
          },
          "update:linked-types": function ($event) {
            return _vm.$set(_vm.form, "restaurant_types", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }